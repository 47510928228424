<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="h1">
        贵州省放射影像专业医疗质量控制中心<br />
        “基于病例的质控培训”第四期顺利召开
      </div>
      <section>
        <div class="div_p">
          2024年10月21日，“基于病例的质控培训”第四期（）线下+线上）成功举办。国家级放射影像专业质控中心主委金征宇教授、副主委薛华丹教授、秘书孙昊教授，贵州省卫健委医政处处长王蕾教授、上海交通大学医学院附属瑞金医院严福华教授、成都市第三人民医院高靳教授、重医二院陈金华教授参会指导，贵州医科大学附属医院胃肠外科严芝强教授、消化内科彭湃澜教授、影像科陈佳妮教授，贵州省人民医院王楠竹教授参会讨论。来至北京、上海、四川、重庆及贵州9个市州的500余名同道参会。会议由贵州省放射影像专业质控中心副主委阮志兵教授、黔东南州放射影像专业质控中心主委粟周海教授、中山一院贵州医院影像科临时负责人于静教授主持。
        </div>
        <div>
          <img src="@/assets/HY20241021/1.png" alt="">
        </div>
        <div class="div_p">
          开幕致辞环节，金征宇主委强调，基于病例的质控培训活动要加强师资建设，质控培训应在国家级放射影像专业质控中心标准化框架内进行，确保培训课件质量与同质化水平，减少地域间差异对质控工作成效的影响。王蕾处长表示，质控工作是确保医疗质量持续提升的保障和重要抓手，各市州放射影像专业质控中心应在省质控中心指导下，加强交流与合作，完善区县级质控中心建设，不断扩大质控工作覆盖面和影响力。“基于病例的质控培训”对于推动我省放射影像专业检查技术规范化、优化影像服务流程、服务水平同质化起到了积极的促进作用。
        </div>
        <div>
          <img src="@/assets/HY20241021/2.png" alt="">
        </div>
        <div class="div_tit">
          开幕致辞
        </div>
        <div class="div_p">
          专题讲座环节，薛华丹教授以《放射影像质控指标内涵和建立流程》为题，从质控指标体系的建设架构、建设思路及7个放射影像质控指标的具体内涵与应用进行了详细的解读，强调以质控指标为抓手，确保患者安全是核心，要避免从死亡中学习，减少从错误中学习，认真从隐患中学习以及积极从榜样中学习，讲座具有重要的实践指导性与借鉴性。
        </div>
        <div>
          <img src="@/assets/HY20241021/3.png" alt="">
        </div>
        <div class="div_tit">
          专题讲座
        </div>
        <div class="div_p">
          病例汇报环节，贵州医科大学第二附属医院龙家全副主任医师从临床病史、实验室检查、影像学和临床表现及影像鉴别诊断等方面对本病例-门静脉积气症进行了详细的汇报，介绍了科室急腹症检查的流程与面临挑战。病例讨论环节，陈佳妮教授、高靳教授、陈金华教授、王楠竹教授、彭湃澜教授及严芝强教授针对如何进行影像图像质控以及如何在短时间内迅速提取有用的影像学信息，对危急值进行准确判断并及时上报展开讨论，对于如何与临床建立畅通的沟通渠道分享了各自的经验和体会。
        </div>
        <div class="img-list">
          <div class="img-item">
            <img src="@/assets/HY20241021/7-1.png" alt="">
            <img src="@/assets/HY20241021/7-2.png" alt="">
          </div>
          <div class="img-item">
            <img src="@/assets/HY20241021/7-3.png" alt="">
            <img src="@/assets/HY20241021/7-4.png" alt="">
          </div>
          <div class="img-item">
            <img src="@/assets/HY20241021/7-5.png" alt="">
            <img src="@/assets/HY20241021/7-6.png" alt="">
          </div>
        </div>
        <div class="div_tit">
          病例汇报及讨论
        </div>
        <div class="div_p">
          孙昊教授在点评环节，针对急腹症危急值上报的范围、流程以及质控指标在整个质控工作中所发挥的作用等方面进行了分析与指导，强调要通过织网和破壁与各临床科室建立完善的质控网络，打破学科间的壁垒，并表示下一步国家级放射专业质控中心将向国家卫健委进行汇报，将“基于病例的质控培训”这种全新的质控模式在全国逐步进行推广。严福华教授指出危急值上报在整个医疗环节中至关重要，要逐步加强技师对危急值的识别能力，不断提升医生及时准确鉴别危急值的能力，落实影像全过程质控，同时强调信息化建设对于落实好危急值上报的重要性。
        </div>
        <div>
          <img src="@/assets/HY20241021/4.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20241021/5.png" alt="">
        </div>
        <div class="div_tit">
          嘉宾点评
        </div>
        <div class="div_p">
          贵州省放射影像专业质控中心高波主委总结表示，基于病例的质控培训是全过程影像质控与培训模式的一种全新探索，活动的开展不仅有利于不同专业间破壁交流沟通，拓展知识面，通过狠抓质控指标，进一步提升我省放射影像质控和检查结果互认工作，推动放射影像专业的医疗服务水平。
        </div>
        <div>
          <img src="@/assets/HY20241021/6.png" alt="">
        </div>


      </section>
    </div>

  </div>
</template>

<script>
export default {
  name: "HY20241021",
  data() {
    return {

    }
  },
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  width: 100%;
  box-sizing: border-box;
  margin: 10px auto;
  display: block;
}

.img-list img {
  display: inline;
  width: 50%;
  margin: 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.h2 {
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
  font-weight: 600;
}
.img-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-item img {
  width: 250px;
  margin: 5px;
  display: inline-block;
}
</style>